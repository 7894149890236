import React from 'react'
import { PiMagnifyingGlassFill } from "react-icons/pi";

function EmptyList(props) {
    return (
        <div className='alert rounded-md alert-info mt-6'>
            <div className="flex space-x-3 items-center">
                <div className="">
                    <PiMagnifyingGlassFill className='h-6 w-6'/>
                </div>
                <div className="">
                    <p>No {props.item} found yet</p>
                </div>
            </div>
        </div>
    )
}

export default EmptyList
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import UnderConstruction from '../components/feedback/UnderConstruction'
import {GiAutoRepair} from 'react-icons/gi'

function HelpPage() {
    const url = window.location.href
    return (
        <HelmetProvider>
            <Helmet>
                <title>Help - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div>
                <h1 className="text-3xl text-center">Help</h1>
                <div className="container mx-auto">
                    <UnderConstruction/>
                    <div className="flex mt-12 gap-3 items-center hover:text-primary">
                        <GiAutoRepair className='h-7 w-7 text-accent'/>
                        <p>Designed and Developed by  gflexx</p>
                    </div>
                </div>                
            </div>
        </HelmetProvider>
    )
}

export default HelpPage
import React, {  useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
// import toast from 'react-hot-toast';
import utils from '../../utils'
// import CommentForm from '../../components/houses/CommentForm'
// import CommentList from '../../components/houses/CommentList'
import { useParams } from 'react-router-dom'
import houseServices from '../../services/HousesService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
// import UserContext from '../../context/UserContext'
// import CartContext from '../../context/CartContext'
// import cartServices from '../../services/CartService'
import HouseMediaItem from './HouseMediaItem';
// import paymentServices from '../../services/PaymentsService';

function HouseViewPage(props) {
    const [house, setHouse] = useState()
    const url = window.location.href

    const houseParams = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    // const [comments, setComments] = useState([])
    const [openMedia, setOpenMedia] = useState([])
    const [closedMedia, setClosedMedia] = useState([])
    const [houseMedia, setHouseMedia] = useState([])
    const [activeImage, setActiveImage] = useState()
    // const [cartLoading, setCartLoading] = useState(false)
    // const [downloadLoading, setDownloadLoading] = useState(false)
    // const userCtx = useContext(UserContext)
    // const isAuth = userCtx.isAuth
    // const cartCtx = useContext(CartContext)
    // const inCart = cartCtx.inCart(houseParams?.slug)
    // const inPurchases = cartCtx.inPurchases(houseParams?.slug)
    // const subscriptionType = userCtx.subscriptionType

    useEffect(() => {
        houseServices.getHouse(houseParams?.slug).then(
            res => {
                setHouse(res.data)
                setActiveImage(utils.baseUrl + res.data?.image)
                setLoading(false)
                houseServices.getHouseMedia(houseParams?.slug).then(
                    res => {
                        setHouseMedia(res.data)
                    },
                    err => {
                        console.log(err);
                    }
                )
                // houseServices.getComments(houseParams?.slug).then(
                //     res => {
                //         setComments(res.data)
                //     },
                //     err => {
                //         toast.error(utils.toasterError)
                //     }
                // )
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }, [houseParams?.slug])
    useEffect(() => {}, [error])
    useEffect(() => {}, [loading])
    // useEffect(() => {}, [comments])
    useEffect(() => {}, [house])
    useEffect(() => {}, [activeImage])
    // useEffect(() => {}, [cartLoading])
    useEffect(() => {}, [openMedia])
    // useEffect(() => {}, [
    //     closedMedia, inPurchases, houseParams?.slug, openMedia
    // ])
    useEffect(() => {
        if (houseMedia) {
            setOpenMedia(houseMedia.filter((media) => media?.show_premium === false))
            setClosedMedia(houseMedia.filter((media) => media?.show_premium === true))
        }
    }, [houseMedia])

    // function submitHandler(details, commentRef){
    //     houseServices.createComment(details).then(
    //         res => {
    //             toast.success("Comment posted succesfully")
    //             commentRef.current.value = null
    //             setComments([res.data, ...comments])
    //         },
    //         err => {
    //             console.log(err);
    //         }
    //     )
    // }
    // function addToCart(){
    //     if (!isAuth) {
    //         return
    //     }
    //     if (inCart) {
    //         toast.error("Item is already in your cart")
    //         return
    //     }
    //     const formData = new FormData()
    //     formData.append("house", house?.id)
    //     const cartId = cartCtx.getCartId()
    //     if (cartId) {
    //         formData.append("cart", cartCtx.getCartId())
    //     } else{
    //         console.log("no cart id");
    //         return
    //     }
    //     formData.append("total", parseFloat(house?.price))
    //     setCartLoading(true)
    //     cartServices.addCartItem(formData).then(
    //         res => {
    //             const data = res.data
    //             cartCtx.setItemSlugs(data?.slugs)
    //             cartCtx.setNumItems(data?.num_items)
    //             toast.success("Item added to cart successfully")
    //             setCartLoading(false)
    //         }, 
    //         err => {
    //             console.log(err);
    //             setCartLoading(false)
    //         }
    //     )
    // }
    // function downloadMedia(){
    //     const purchasedItems = cartCtx.purchasedItems
    //     if (subscriptionType !== "Active") {
    //         const item = purchasedItems.filter((item) => item?.slug === house?.slug)
    //         if (!item.length) {
    //             console.log("no item");
    //             toast.error(utils.toasterError)
    //             setDownloadLoading(false)
    //             return
    //         }
    //         downloadItem(item[0]?.id, 0)
    //     }        
    //     downloadItem(house?.id, 1)
    // }
    // function downloadItem(id, action){
    //     setDownloadLoading(true)
    //     paymentServices.downloadItemMedia(id, action).then(
    //         res => {
    //             utils.downloadStream(res.data, house?.slug)
    //             setDownloadLoading(false)
    //         },
    //         err => {
    //             setDownloadLoading(false)
    //             toast.error(utils.toasterError)
    //         }
    //     )
    // }
    return (
        <HelmetProvider>
            <div>
                <div className='px-1 md:px-4 lg:px-12'>
                    {loading ? (
                        <Loading/>
                    ):(
                        <div>
                            {error ? (
                                <FetchError error={error}/>
                            ):(
                                <div className="">
                                    <Helmet>
                                        <title>{house?.name} - {utils.siteName}</title>
                                        <meta property="description" content={house?.description}/>
                                    
                                        <meta property="og:title" content={house?.name}/>
                                        <meta property="og:image" content={utils.baseUrl + house?.image}/>
                                        <meta property="og:url" content={url}/>
                                        <meta property="og:description" content={house?.description}/>

                                        <meta name="twitter:card" content={utils.baseUrl + house?.image}/>
                                        <meta name="twitter:title" content={house?.name} />
                                        <meta name="twitter:description" content={house?.description || "Join us for an exciting event"} />
                                        <meta name="twitter:image" content={utils.baseUrl + house?.image} />
                                        <meta name="twitter:url" content={url} />
                                        
                                        <link rel="canonical" href={url} />

                                        <script type="application/ld+json">
                                            {`
                                                {
                                                    "@context": "https://schema.org",
                                                    "@type": "Architectural Plan",
                                                    "name": "${house?.name}",
                                                    "image": ["${utils.baseUrl + house?.image}"],
                                                    "description": "${house?.description}"
                                                }
                                            `}
                                        </script>
                                    </Helmet>
                                    <div className="md:flex md:gap-4 lg:gap-6 container mx-auto mt-4">
                                        <div className="md:w-6/12 mb-5 md:mb-0">
                                            <div className="grid gap-2">
                                                <div>
                                                    <img className="h-auto max-w-full rounded-md" src={activeImage} alt={house?.name}/>
                                                </div>
                                                <div className="grid grid-cols-5 gap-2 items-center">
                                                    <div>
                                                        <img 
                                                            onClick={() => setActiveImage(utils.baseUrl + house?.image)}
                                                            className="h-auto max-w-full rounded-md hover:cursor-pointer" src={utils.baseUrl + house?.image} alt={house?.name}/>
                                                    </div>
                                                    {openMedia && (
                                                        <>
                                                            {openMedia?.map((media, idx) => (
                                                                <HouseMediaItem
                                                                    key={idx}
                                                                    media={media}
                                                                    setActiveImage={setActiveImage}
                                                            />
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                                {/* {isAuth && (
                                                    <div className="grid grid-cols-5 gap-2 items-center">
                                                        {((closedMedia && inPurchases) || (subscriptionType === "Active")) && (
                                                            <>
                                                                {closedMedia?.map((media, idx) => (
                                                                    <HouseMediaItem
                                                                        key={idx}                                                                   
                                                                        media={media}
                                                                        setActiveImage={setActiveImage}
                                                                    />
                                                                ))}
                                                            </>
                                                        )}
                                                    </div>
                                                )} */}
                                                {closedMedia?.length > 0 && (
                                                    <div className='rounded-md border p-3 bg-base-300 text-sm'>
                                                        <p>
                                                            {/* {(!inPurchases && subscriptionType !== "Active") && (
                                                                <span>Buy to Access  </span>
                                                            )} */}
                                                            Hidden Files: <span className="font-bold">{closedMedia?.length}</span>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='md:w-6/12'>
                                            <div className="card rounded-md border hover:shadow-lg p-4 bg-base-200 hover:bg-base-300">
                                                <div className="space-y-3">
                                                    <h1 className="text-2xl text-secondary font-bold">{house?.name}</h1>
                                                    <p>Price: <span className="font-bold">KSH {utils.toCommas(house?.price)}</span></p>
                                                    <p>Building Style: <span className="font-bold">{house?.category?.name}</span></p>
                                                    {/* <p>Owner: <span className="font-bold">{house?.owner?.email}</span></p> */}
                                                    <p>Views: <span className="font-bold">{house?.views}</span></p>
                                                    <p>Stories: <span className="font-bold">{house?.stories}</span></p>
                                                    <p>Bedrooms: <span className="font-bold">{house?.bedrooms}</span></p>
                                                    <p>Bathrooms: <span className="font-bold">{house?.bathrooms}</span></p>
                                                    <p>Description:</p>
                                                    <p className='text-black'>{house?.description}</p>
                                                    {/* <div className=''>
                                                        {cartLoading ? (
                                                            <Loading/>
                                                        ):(
                                                            <div>
                                                                {isAuth ? (
                                                                    <span>
                                                                        {(inPurchases || (subscriptionType === "Active")) ? (
                                                                            <div>
                                                                                {downloadLoading ? (
                                                                                    <Loading/>
                                                                                ):(
                                                                                    <button 
                                                                                        onClick={() => downloadMedia()}
                                                                                        className='btn btn-neutral btn-wide mt-5 mb-4'>Download Media</button>
                                                                                )}
                                                                            </div>
                                                                        ):(
                                                                            <button 
                                                                            onClick={() => addToCart()}
                                                                            className='btn btn-secondary btn-wide mt-5 mb-4'>Add to Cart</button>
                                                                        )}
                                                                        
                                                                    </span>
                                                                ):(
                                                                    <span>
                                                                        <a href='/auth/login' className='btn btn-wide btn-neutral mt-5 mb-4'>Login to Use cart</a>
                                                                    </span>
                                                                )}
                                                                
                                                            </div>
                                                        )}
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="flex justify-center mb-16">
                                        <div className="w-11/12 md:w-10/12">
                                            <h3 className="text-2xl mb-3">Comments: <span>{comments.length}</span></h3>
                                            <CommentForm
                                                submitHandler={submitHandler}
                                                house={house}
                                            />
                                            <CommentList
                                                comments={comments}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </HelmetProvider>
    )
}

export default HouseViewPage
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import {AiFillHome, AiFillDashboard} from 'react-icons/ai'
import {BiPowerOff} from 'react-icons/bi'
import {BsFillPersonFill, BsFillInfoCircleFill} from 'react-icons/bs'
import {HiHomeModern} from 'react-icons/hi2'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import UserContext from '../../context/UserContext'
import { FaSquarePen } from "react-icons/fa6"
import { MdDashboardCustomize } from 'react-icons/md'

function Drawer(props) {
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    const user = userCtx.user
    const location = useLocation()
    const navigate = useNavigate()

    function logout(){
        userCtx.logout()
        navigate('/')
    }
    function handleUlClick(event) {
        if (event.target.closest('a')) {
            const drawerToggle = document.getElementById("nav-drawer")
            drawerToggle.click()
        }
    }
    function openModal(){
        const openBtn = document.getElementById("contact-modal-open-btn")
        openBtn.click()
    }
    return (
        <div className="drawer-side z-50">
            <label htmlFor="nav-drawer" className="drawer-overlay"></label>
            <ul 
                onClick={handleUlClick}
                className="menu p-4 w-64 bg-base-100 h-full text-base-content">
                <li>
                    <Link
                        to='/' 
                        className={location.pathname === '/' ? 'active': ''}>
                            <AiFillHome/> Home</Link>
                </li>
                <li>
                    <Link
                        to='/about' 
                        className={location.pathname === '/about' ? 'active': ''}>
                            <BsFillInfoCircleFill/> About</Link>
                </li>
                <li>
                    <Link
                        to='/houses' 
                        className={location.pathname === '/houses' ? 'active': ''}>
                            <HiHomeModern/> Houses</Link>
                </li>
                <li>
                    <Link
                        to='/blog' 
                        className={location.pathname === '/blog' ? 'active': ''}>
                            <FaSquarePen/> Blog</Link>
                </li>
                <li>
                    <a 
                        onClick={() => openModal()}
                        ><MdDashboardCustomize/> Custom Plan</a>
                </li>
               
                {!isAuth ? (
                    <>
                    </>
                ):(
                    <>
                         <li className="menu-title">
                            <span>Account</span>
                        </li>
                        <li>
                            <Link
                                to='/auth/profile' 
                                className={location.pathname === '/auth/profile' ? 'active': ''}>
                                    <BsFillPersonFill/> Profile</Link>
                        </li>
                        {user && (
                            <>
                                {user?.is_superuser && (
                                    <>
                                        <li>
                                            <Link
                                                to='/auth/admin' 
                                                className={location.pathname === '/auth/admin' ? 'active': ''}>
                                                    <AiFillDashboard/> Admin</Link>
                                        </li>
                                    </>
                                )}
                            </>
                        )}
                        <li>
                            <a onClick={() => logout()}><BiPowerOff /> Logout</a>
                        </li>
                    </>
                )}
            </ul>
        </div>
    )
}

export default Drawer
import React, { useContext, useEffect } from 'react'
import SessionContext from '../../context/SessionContex'
import CategoryItem from '../houses/CategoryItem'

function Categories() {
    const sessionCtx = useContext(SessionContext)
    const categories = sessionCtx.categories

    useEffect(() => {}, [categories])
    return (
        <div className='container mx-auto p-3'>
            <h2 className="text-primary uppercase font-semibold tracking-wider mb-6">Building Types</h2>
            <p className='text-xl md:text-2xl text-secondary mb-6 font-bold'>Plan For Every Complex Building</p>
            <p className='mb-8'>We have a wide variety of cost effective Building Plans available, you can search for, chose or compare. Each of the Plans contain additional images, files and descriptions.</p>
            <div className="">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {categories.map((category, idx) => (
                        <CategoryItem
                            key={idx}
                            category={category}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Categories
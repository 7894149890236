import React from 'react'
import {BsFacebook, BsTelephoneFill} from 'react-icons/bs'
import {IoIosMailOpen} from 'react-icons/io'
import {ImAddressBook} from 'react-icons/im'
import { FaInstagramSquare } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import utils from '../../utils'
import { Link } from 'react-router-dom'

function Footer(props) {
    function openModal(){
        const openBtn = document.getElementById("contact-modal-open-btn")
        openBtn.click()
    }
    return (
        <div className='mt-28 bottom bottom-auto'>
            <div className="bg-primary py-5"></div>
            <div className="py-3 bg-neutral">
                <div className="container mx-auto pb-5">
                    <div className='grid md:grid-cols-3 gap-4'>
                        <div>
                            <p className='text-success text-lg md:text-2xl text-center hover:text-info uppercase'>Contact Info</p>
                            <div className='mt-3 md:ml-0 text-center'>
                                <p className="text-base-200 text-lg font-bold mb-2">Telephone</p>
                                <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center'>
                                    <div>
                                        <BsTelephoneFill className='h-5 w-5 text-primary'/>
                                    </div>
                                    <div>
                                        <p>+254108455455</p>
                                    </div>
                                </span>
                                <p className="text-base-200 text-lg font-bold mb-2 mt-4">Email</p>
                                <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center mb-4'>
                                    <div>
                                        <IoIosMailOpen className='h-6 w-6 text-primary'/>
                                    </div>
                                    <div>
                                        <p>info@jahtechconstruction.co.ke</p>
                                        <p>plans@jahtechconstruction.co.ke</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div>
                            <p className='text-success text-lg md:text-2xl text-center hover:text-info uppercase'>Important Links</p>
                            <div className='mt-3 space-y-2 md:ml-0 text-center'>
                                <p><Link to="/" className="text-base-100 hover:text-primary">Home</Link></p>
                                <p><Link to="/about" className="text-base-100 hover:text-primary">About Us</Link></p>
                                <p><Link to="/houses" className="text-base-100 hover:text-primary">Houses</Link></p>
                                <p><Link to="/blog" className="text-base-100 hover:text-primary">Blog</Link></p>
                                <p><span 
                                    onClick={() => openModal()}
                                    className="text-base-100 hover:text-primary">Custom Plan</span></p>
                            </div>
                        </div>
                        <div>
                            <p className='text-success text-lg md:text-2xl text-center hover:text-info uppercase'>Get Social</p>
                            <div className="gap-3 mt-3 md:ml-0 text-center">
                                <a href="https://web.facebook.com/profile.php?id=100095247722829" target="_blank" rel="noopener noreferrer" className='mb-3'> 
                                    <span className='flex text-base-100 hover:text-primary  space-x-3 justify-center'>
                                        <div>
                                            <BsFacebook className='h-5 w-5 text-primary'/>
                                        </div>
                                        <p>{utils.siteName}</p>
                                    </span>
                                </a>
                                <a href="https://x.com/Jahtech_consult" target="_blank" rel="noopener noreferrer" className='mb-3'>
                                    <span className='flex text-base-100 hover:text-primary space-x-3 justify-center'>
                                        <div>
                                            <BsTwitterX className='h-5 w-5 text-primary'/>
                                        </div>
                                        <p>Jahtech_consult</p>
                                    </span>
                                </a>
                                <a href="https://www.instagram.com/jahtech_construction/" target="_blank" rel="noopener noreferrer" className='mb-3'> 
                                    <span className='flex text-base-100 hover:text-primary space-x-3 justify-center'>
                                        <div>
                                            <FaInstagramSquare className='h-5 w-5 text-primary'/>
                                        </div>
                                        <p>Jahtech_construction</p>
                                    </span>
                                </a>
                                <div className="">
                                    <p className="text-base-200 text-lg font-bold mb-2 mt-4">Address</p>
                                    <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center'>
                                        <div>
                                            <ImAddressBook className='h-5 w-5 text-primary'/>
                                        </div>
                                        <div>
                                            <p>56415-00200</p>
                                            <p>Nairobi, KENYA</p>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
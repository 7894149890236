/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import {BsFillPersonFill} from 'react-icons/bs'
import {AiFillDashboard} from 'react-icons/ai'
import {BiPowerOff} from 'react-icons/bi'
// import {RiMoneyDollarBoxFill} from 'react-icons/ri'
import UserContext from '../../context/UserContext'
import utils from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
// import CartContext from '../../context/CartContext'

function ProfileSideBar(props) {
    const userCtx = useContext(UserContext)
    const navigate = useNavigate()
    const user = userCtx.user
    const {active} = props
    
    function logout(){
        userCtx.logout()
        navigate('/')
    }
    return (
        <div className='hidden md:flex md:w-4/12 lg:w-1/4 card'>
            <div className="bg-base-300  rounded-md hover:shadow-xl border sticky top-0">
                {user && (
                    <>
                        <div className="flex justify-center">
                            <div className='avatar placeholder mb-3 mt-7'>
                                <div className=''>
                                    {utils.hasProfileImage(user) ? (
                                        <div className="w-40 avatar placeholder">
                                            <img className=' rounded-md' src={user?.image} alt={user?.email}/>
                                        </div>
                                    ):(
                                        <div className="avatar placeholder">
                                            <div className="h-36 bg-neutral-focus text-neutral-content rounded-full">
                                                <span className="text-3xl">
                                                    {utils.makeProfileLetter(user)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div> 
                        </div>
                        <p className="text-center mb-3">{user?.email}</p>
                    </>
                )}
                <ul className="menu mb-6">
                    <li>
                        <Link
                            to="/auth/profile"
                            className={active === 'profile' ? 'active' : ''}>
                                <BsFillPersonFill className='mr-4'/>Profile</Link>
                    </li>
                    {user && (
                        <>
                            {user?.is_admin && (
                                <li>
                                    <Link
                                        to="/auth/admin"
                                        className={active === 'admin' ? 'active' : ''}>
                                            <AiFillDashboard className='mr-4'/>Admin</Link>
                                </li>
                            )}
                        </>
                    )}
                    {/* <li>
                        <Link
                            to="/auth/payments"
                            className={active === 'payments' ? 'active' : ''}>
                                <RiMoneyDollarBoxFill className='mr-4'/>Payments</Link>
                    </li> */}
                    <li><a onClick={() => logout()}><BiPowerOff className='mr-4 text-accent'/>Logout</a></li>
                </ul>
            </div>
        </div>
    )
}

export default ProfileSideBar
const onProduction = true

const baseUrl = onProduction ? 'https://jahtechconstruction.co.ke' : 'http://127.0.0.1:8000'

const siteName = "JahTech Construction Consultants"

const multipartHeader = {"Content-Type": "multipart/form-data"}

const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const makeProfileLetter = (user) => {
    const mail = user?.email
    if (!mail) {
        return '0'
    }
    const letter = mail.charAt(0)
    return letter.toUpperCase()
}

const hasProfileImage = (user) => {
    const img = user?.image
    if (!img) {
        return false
    }
    return true
}

const getEmailName = (user) => {
    // const
}

let room = "Bedroom"
let storiesName = "Stories"
let bathroomName = "Bathroom"

const roomNames = [
    {
        "name": "1 " + room,
        "value": 1
    },
    {
        "name": "2 " + room,
        "value": 2
    },
    {
        "name": "3 " + room,
        "value": 3
    },
    {
        "name": "4+ " + room,
        "value": 4
    },
]

const stories = [
    {
        "name": "1 " + storiesName,
        "value": 1
    },
    {
        "name": "2 " + storiesName,
        "value": 2
    },
    {
        "name": "3 " + storiesName,
        "value": 3
    },
    {
        "name": "4+ " + storiesName,
        "value": 4
    },
]

const bathrooms = [
    {
        "name": "1 " + bathroomName,
        "value": 1
    },
    {
        "name": "2 " + bathroomName,
        "value": 2
    },
    {
        "name": "3 " + bathroomName,
        "value": 3
    },
    {
        "name": "4+ " + bathroomName,
        "value": 4
    },
]

const toasterPoisition = "bottom-center"

const toasterOptions = {
    className: "rounded-md border"
}

const toasterError = "Something went wrong"

const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg", "webp", "ico"]; 

const isImage = (filePath) => { 
    if (!filePath || typeof filePath !== "string") return false; 
    const split = filePath.split('.')
    if (split.length < 2) return false; 
    const extension = split.pop().toLowerCase(); 
    return imageExtensions.includes(extension); 
};

const getPageValue = (url) =>{
    const urlString = url.split("?")[1]
    const param = new URLSearchParams(urlString)
    return param.get("page")
}

const getCurrentPage = (previous, next) => {
    if (next !== null) {
        const nextPage = getPageValue(next)
        return parseInt(nextPage) - 1
    } else {
        if (previous !== null) {
            const previousPage = getPageValue(previous)
            if(isNaN(parseInt(previousPage))){
                return 2
            }
            return parseInt(previousPage) + 1
        }
    }
}

const chartOptions = {
    maintainAspectRatio:false,
    responsive:true,
}

const downloadStream = (data, slug) => {
    const blob = new Blob([data], {type: "application/zip"});
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${slug}.zip`;
    link.click();
}

const changeUrlPageParam = (
    value, value_type, hasFiltered, 
    setHasFiltered, filterCtx, setSearchParams) => {
    if (!hasFiltered) {
        setHasFiltered(true)
    }
    setSearchParams((params) => {
        params.delete("page")
        return params
    })
    if (value_type === "bedrooms") {
        filterCtx.setHouseRooms(parseInt(value))
    } else if( value_type === "stories"){
        filterCtx.setHouseStories(value)
    } else if(value_type === "bathrooms"){
        filterCtx.setHouseBathrooms(value)
    } else if(value_type === "category__slug"){
        filterCtx.setHouseCategory(value)
    }
    if (value) {
        setSearchParams((params) => {
            params.set(value_type, value)
            return params
        })
    } else{
        setSearchParams((params) => {
            params.delete(value_type)
            return params
        })
    }
}

const inputModules = {
    toolbar : [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        
        ['blockquote', 'code-block'],           
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],     
        [{ 'indent': '-1'}, { 'indent': '+1' }],         
        [{ 'direction': 'rtl' }],                        
        [{ 'size': ['small', false, 'large', 'huge'] }],             
        ['link', 'image']
        ['clean']                                         
    ]
}

const createProfileLetter = (user) => {
    const email = user?.email
    if (!email) {
        return '0'
    }
    const letter = email.charAt(0)
    return letter
}

const utils = {
    siteName,
    toCommas,
    baseUrl,
    makeProfileLetter,
    hasProfileImage,
    multipartHeader,
    getEmailName,
    roomNames,
    stories,
    bathrooms,
    toasterPoisition,
    toasterOptions,
    toasterError,
    isImage,
    getPageValue,
    getCurrentPage,
    chartOptions,
    downloadStream,
    changeUrlPageParam,
    inputModules,
    createProfileLetter
}

export default utils
import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import { useSearchParams } from 'react-router-dom'
import blogServices from '../../services/BlogService'
import Pagination from '../../components/nav/Pagination'
import BlogList from '../../components/blog/BlogList'

function BlogListPage() {
    const [searchParam, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [blogs, setBlogs] = useState()

    useEffect(() => {
        const params = searchParam.toString()
        setLoading(true)
        blogServices.getBlogs(params ? `?${params}` : '').then(
            res => {
                setBlogs(res.data)
                setLoading(false)
            }, 
            err => {
                console.log(err)
                setError(err)
                setLoading(false)
            }
        )
    }, [searchParam])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [blogs])
    
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Blog - {utils.siteName}</title>
            </Helmet>
            <div className='container mx-auto mt-4'>
                <div>
                    <h1 className="text-center text-3xl mb-6">Blog Posts</h1>
                    <hr  className='mb-6'/>
                    {loading ? (<Loading/>):(
                        <div>
                            {error ? (<FetchError error={error}/>):(
                                <div>
                                    <BlogList blogs={blogs}/>
                                    {((blogs?.next !== null) || (blogs?.previous !== null)) && (
                                        <Pagination
                                            next={blogs?.next}
                                            previous={blogs?.previous}
                                            changePageParam={changePageParam}
                                            isAdmin={false}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </HelmetProvider>
    )
}

export default BlogListPage
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import blogServices from '../../services/BlogService'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import utils from '../../utils'
import moment from 'moment'

function BlogViewPage() {
    const [post, setPost] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const postParam = useParams()

    useEffect(() => {
        setError()
        blogServices.getSpecificBlog(postParam?.slug).then(
            res => {
                setPost(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }, [postParam?.slug])
    useEffect(() => {}, [error])
    useEffect(() => {}, [post])
    return (
        <HelmetProvider>
            <div className='mt-4 container mx-auto'>
                {loading ? (<Loading/>):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <Helmet>
                                    <title>{post?.name} - {utils.siteName}</title>
                                </Helmet>
                                <div className='flex md:justify-center md:gap-4 lg:gap-7 md:px-2 lg:px-16 mb-16'>
                                    <div className="hero min-h-96 md:rounded-md" style={{ backgroundImage: `url(${utils.baseUrl + post?.image})` }}>
                                        <div className=""></div>
                                    </div>
                                </div>
                                <h1 className="text-4xl text-center text-secondary font-bold mb-6">{post?.name}</h1>
                                <div className='container mx-auto'>
                                    <p className=' text-center mb-12'>Created: <span className='text-primary'>{moment(post?.creation_time).format("Do mm yyyy")}</span></p>
                                    <article className="flex justify-center">
                                        <div className='prose'  
                                            dangerouslySetInnerHTML={{ __html: post?.text}}
                                        />
                                    </article>
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default BlogViewPage
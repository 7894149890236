import React, { useContext } from 'react'
import UserContext from '../../context/UserContext'
import moment from 'moment'
import utils from '../../utils'

function ProfileDetailsTab(props) {
    const userCtx = useContext(UserContext)
    const user = userCtx.user
    return (
        <div>
            <h2 className="text-2xl mb-3">Profile Details</h2>
            <div className="profile__details">
                <div className="flex justify-center md:hidden">
                    <div className='avatar placeholder mb-7 mt-4'>
                        <div className=''>
                            {utils.hasProfileImage(user) ? (
                                <div className="h-32 lg:h-36 rounded-md avatar placeholder">
                                    <img src={user?.image} alt={user?.email}/>
                                </div>
                            ):(
                                <div className="avatar placeholder">
                                    <div className="h-32 lg:h-36 bg-neutral-focus text-neutral-content mask rounded">
                                        <span className="text-3xl">
                                            {utils.makeProfileLetter(user)}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-5 text-sm space-y-3'>
                <p>Email: <span className='ml-3'>{user?.email}</span></p>
                <p>Joined: <span className='ml-3'>{moment(user?.creation_time).format("MMMM Do YYYY")}</span></p>
                <p>Updated: <span className='ml-3'>{moment(user?.last_updated_time).format("MMMM Do YYYY")}</span></p>
                <p>Account Type:</p>
                <div className="flex gap-3">
                    {user?.is_admin && (<div className="badge badge-primary">Admin Account</div>)}
                    {user?.is_provider && (<div className="badge badge-primary">Provider Account</div>)}
                    {!user?.is_admin && !user?.is_provider && (<div className="badge badge-primary">User Account</div>)}
                </div>
            </div>
            <hr className='mb-5'/>
            <div>
                <button className='btn btn-secondary'>Advanced Actions</button>
            </div>
        </div>
    )
}

export default ProfileDetailsTab
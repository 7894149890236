/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import utils from '../../utils'
import { FaSquarePen } from "react-icons/fa6"
import {HiHomeModern} from 'react-icons/hi2'
import { Link } from 'react-router-dom'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { MdDashboardCustomize } from "react-icons/md"

function NavBar(props) {
    function openModal(){
        const openBtn = document.getElementById("contact-modal-open-btn")
        openBtn.click()
    }
    return (
        <div className='navbar navbar-center flex justify-center shadow-md lg:px-6 z-30'>
            <div className="space-x-2">
                <label htmlFor="nav-drawer">
                    <div className="btn btn-ghost btn-circle mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                    </div>
                </label>
                <div className=''>
                    <Link className="btn btn-ghost upper-case font-extrabold text-secondary" to='/'>{utils.siteName}</Link>
                </div>
            </div>
            <div className="space-x-2">
                <div className="hidden md:flex">
                    <ul className="menu menu-horizontal">
                        <li>
                            <Link
                                to='/about' >
                                <BsFillInfoCircleFill/> About</Link>
                        </li>
                        <li><Link to="/houses" className=""><HiHomeModern/> All Plans</Link></li>
                        <li><Link to="/blog" className=""><FaSquarePen/> Blog</Link></li>
                        <li>
                            <a 
                                onClick={() => openModal()}
                                ><MdDashboardCustomize/> Custom Plan</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NavBar
import React, { useContext, useEffect, useRef, useState } from 'react'
import SessionContext from '../../context/SessionContex'
import FormError from '../feedback/FormError'
import Loading from '../feedback/Loading'
import UserContext from '../../context/UserContext'
import {HiTrash} from 'react-icons/hi'
import utils from '../../utils'
import houseServices from '../../services/HousesService'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

function HouseDetailsTab(props) {
    const sessionCtx = useContext(SessionContext)
    const [show, setShow] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const categories = sessionCtx.categories
    const userCtx = useContext(UserContext)
    const nameRef = useRef()
    const categoryRef = useRef()
    const descriptionRef = useRef()
    const bedroomRef = useRef()
    const bathroomRef = useRef()
    const imageRef = useRef()
    const priceRef = useRef()
    const [file, setFile] = useState()
    const storyRef = useRef()
    const loading = props.loading
    const error = props.error
    const action = props.action
    const updateHouse = props.updateHouse
    const navigate = useNavigate()

    useEffect(() => {}, [categories])
    useEffect(() => {
        if (action === 2) {
            if(updateHouse?.name){
                nameRef.current.value = updateHouse?.name
                if (updateHouse?.description !== undefined) {
                    descriptionRef.current.value = updateHouse?.description
                }
                bedroomRef.current.value = updateHouse?.bedrooms
                bathroomRef.current.value = updateHouse?.bathrooms
                storyRef.current.value = updateHouse?.stories
                priceRef.current.value = updateHouse?.price
            }
        }
    }, [action, updateHouse])

    function imageChanged(event){
        setFile(event.target.files[0])
    }
    function handleSubmit(e){
        e.preventDefault()
        const formData = new FormData()
        formData.append("name", nameRef.current.value)
        formData.append("description", descriptionRef.current.value)
        if (action === 1){
            formData.append("category", categoryRef.current.value)
            formData.append("owner", parseInt(userCtx.user?.id))
        }
        formData.append("bedrooms", parseInt(bedroomRef.current.value))
        formData.append("price", parseInt(priceRef.current.value))
        formData.append("bathrooms", parseInt(bathroomRef.current.value))
        formData.append("stories", parseInt(storyRef.current.value))
        if (file){
            formData.append("image", file)
        }
        props.submitHandler(formData)
    }
    function deleteHouse(house){
        console.log(house);
        setDeleteLoading(true)
        houseServices.deleteHouse(house?.slug).then(
            res => {
                toast.success("House deleted successfully")
                setDeleteLoading(false)
                navigate('/auth/admin')
            },
            err => {
                toast.error("Something went wrong")
                setDeleteLoading(false)
            }
        )
        
    }
    return (
        <div className=''>
            <div className='flex justify-center'>
                <div className='w-10/12 md:w-8/12 lg:w-6/12'>
                    <form onSubmit={handleSubmit} className='rounded-md border p-5 md:p-9 hover:shadow-lg mb-5'>
                        <h2 className="text-2xl text-center mb-5">House Details</h2>
                        {error && (
                            <FormError error={error}/>
                        )}
                        {action === 2 &&(
                            <div className='flex justify-center'>
                                <img className='rounded-md h-44' src={utils.baseUrl + updateHouse?.image} alt={updateHouse?.name} />
                            </div>
                        )}
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input type="text" required ref={nameRef} name="name" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Category</span>
                            </label>
                            {action === 1 ? (
                                <select ref={categoryRef} className="select select-bordered w-full max-w-xs input-success">
                                    {categories.length && (
                                        <>
                                            {categories.map((category, idx) => (
                                                <option key={idx} value={category?.id}>{category?.name}</option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            ): (
                                <span>Current category: <span className='text-secondary'>{updateHouse?.category?.name}</span></span>
                            )}
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Image</span>
                            </label>
                            {action === 1 ? (
                                <input 
                                type="file" 
                                required
                                ref={imageRef} 
                                id='file-input' 
                                onChange={imageChanged}
                                className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                            ):(
                                <input 
                                type="file" 
                                ref={imageRef} 
                                id='file-input' 
                                onChange={imageChanged}
                                className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                            )}
                            
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Price</span>
                            </label>
                            <input type="number" min={0} required ref={priceRef} name="price" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Bedrooms</span>
                            </label>
                            <input type="number" min={0} required ref={bedroomRef} name="bedrooms" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Bathrooms</span>
                            </label>
                            <input type="number" min={0} required ref={bathroomRef} name="bathrooms" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Stories</span>
                            </label>
                            <input type="number" min={0} required ref={storyRef} name="stories" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="form-control mb-5">
                            <label className="label">
                                <span className="label-text">Description</span>
                            </label>
                            <textarea cols={4} required ref={descriptionRef} name="name" className="input text-gray-600 input-bordered w-full h-20 input-success" />
                        </div>
                        <div className="flex justify-center mb-6">
                            {loading ? (
                                <Loading/>
                            ):(
                                <button type="submit" className='btn btn-secondary'>Submit</button>
                            )}
                        </div>
                    </form>
                    {action === 2 && (
                        <div className="alert alert-warning rounded-md mb-5">
                            <div className="w-full">
                                <div className="flex space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                    <span>Warning: Deleting is irreversable!</span>
                                </div>
                                {show ? (
                                    <div className="mt-4">
                                        {deleteLoading ? (
                                            <Loading/>
                                        ):(
                                            <div className="">
                                                {error ? (
                                                    <FormError error={error}/>
                                                ):(
                                                    <button 
                                                        onClick={() => deleteHouse(updateHouse)}
                                                        className='btn btn-error'><HiTrash/> Delete Parmanently</button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ):(
                                    <div className="mt-4">
                                        <button 
                                            onClick={() => setShow(!show)}
                                            className='btn btn-neutral'><HiTrash/>  Delete {updateHouse?.name}</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HouseDetailsTab
import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";
import construction from '../../assets/building.jpg'

function ConstructionSection(props) {
    return (
        <div>
            <div className="bg-purple-100 py-20  p-3">
                <div className="flex flex-col md:flex-row items-center justify-between gap-8 container mx-auto">
                    <div className="max-w-lg">
                        <p className="text-primary uppercase font-semibold tracking-wider">Construction Project Management</p>
                        <h1 className="text-3xl md:text-4xl font-bold text-purple-800 mt-6">
                            Taking Good Control Of Our Clients' Construction Needs
                        </h1>
                        <p className="text-gray-600 mt-6">
                            We will manage your construction projects, from start to finish, ensuring top-quality results at the
                            best cost possible.
                        </p>
                        <button 
                            onClick={() => props.openModal()}
                            className="btn btn-outline btn-secondary mt-8 flex items-center gap-2">
                            For Bookings & Inquiries <span><FaArrowRightLong/></span>
                        </button>
                    </div>
                    <div className="relative">
                        <div className="absolute top-0 right-0 h-6 w-6 border-t-8 border-r-8 border-blue-600"></div>
                        <div className="absolute bottom-0 left-0 h-6 w-6 border-b-8 border-l-8 border-blue-600"></div>
                        <img
                            src={construction}
                            alt="Construction Project"
                            className="rounded-md shadow-lg"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConstructionSection
import React, { useEffect, useState } from 'react'
// import plann from '../../assets/plan1.png'
import { Link } from 'react-router-dom'
import { BsFillHouseAddFill } from "react-icons/bs";
import img1 from '../../assets/render1.jpg'
import img2 from '../../assets/render2.jpg'
import img3 from '../../assets/render3.jpg'


const imgs = [
    img1, img2, img3
]

function Hero() {
    const [images, setImages] = useState([])
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    useEffect(() => {
        const processedImages = imgs.map((img) => `url(${img})`)
        setImages(processedImages);
    }, []);
    useEffect(() => {}, [images])
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
        }, 3000);
        return () => clearInterval(intervalId);
    }, [images]);
    return (
        <div className=''>
            <div 
                className="hero carousel__img" 
                style={{
                    backgroundImage: images[currentImageIndex],
                }}
                >
                <div className="hero-overlay bg-opacity-60"></div>
                <div className="hero-content justify-center flex-col">
                    <h2 className='text-2xl md:text-4xl font-bold text-white text-center mb-6'>Helping You Achive Your Goals Through Great Architectural Plans and Designs.</h2>
                    <div className="flex justify-center">
                        <Link to={'/houses'} className='btn btn-secondary btn-md text-white'>Explore More Plans <BsFillHouseAddFill/></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
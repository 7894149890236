import React, { useEffect, useState } from 'react'
import blogServices from '../../services/BlogService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import BlogList from '../blog/BlogList'
import { Link } from 'react-router-dom'

function BlogListHome() {
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {
        setError()
        blogServices.getBlogs('').then(
            res => {
                setPosts(res.data)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }, [])
    useEffect(() => {}, [posts])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    return (
        <div className='container mx-auto p-3'>
            <h2 className="text-primary uppercase font-semibold tracking-wider mb-6">Our Blog</h2>
            <p className='text-xl md:text-2xl text-secondary mb-6 font-bold'>Gain Some Knowledge From our Posts </p>
            <p className='mb-8'>Get information about buildings, construction and all in between from our industry certified professionals.</p>
            {loading ? (<Loading/>):(
                <div>
                    {error ? (<FetchError error={error}/>):(
                        <div>
                            <BlogList blogs={posts}/>
                            <div className="flex justify-center">
                                <Link to={'/blog'} className='btn btn-wide mt-5 btn-secondary'>View More Posts</Link>
                            </div>                           
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default BlogListHome
import React, { useEffect, useState } from 'react'
import BlogSammary from './BlogSammary'
import BlogTable from './BlogTable'
import { useSearchParams } from 'react-router-dom'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import blogServices from '../../services/BlogService'

function AdminBlogTab(props) {
    const [blogs, setBlogs] = useState()
    const [blogInfo, setBlogInfo] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const activeTab = props.activeTab
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {}, [blogs])
    useEffect(() => {}, [blogInfo])
    useEffect(() => {
        if (activeTab === 3) {
            const params = searchParams.toString()
            getBlogs(params)
        }
    }, [activeTab, searchParams])
    useEffect(() => {
        getBlogInfo()
    }, [])

    const getBlogs = (params) => {
        setLoading(true)
        setError()
        blogServices.adminGetBlogs(params ? `?${params}` : '').then(
            res => {
                setBlogs(res.data)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }
    const getBlogInfo = () => {
        blogServices.adminBlogStatistics().then(
            res => {
                setBlogInfo(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }
    function filterPage(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            if (searchParams.has("page")) {
                setSearchParams(
                    searchParams.delete("page")
                )
            }
        }
    }
    return (
        <div>
            {loading ? (<Loading/>): (
                <div>
                    {error ? (<FetchError error={error}/>):(
                        <div>
                            <BlogSammary 
                                blogInfo={blogInfo}/>
                            <BlogTable
                                filterPage={filterPage}
                                blogs={blogs}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AdminBlogTab
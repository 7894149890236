import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import Contacts from '../components/home/Contacts'
import plann from '../assets/plan1.png'
import QuoteForm from '../components/home/QuoteForm'

function AboutPage() {
    const url = window.location.href
    function openModal(){
        const openBtn = document.getElementById("contact-modal-open-btn")
        openBtn.click()
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>About Us - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div>
            <QuoteForm/>
            <div className=''>
                <div className="hero min-h-96" style={{ backgroundImage: `url(${plann})` }}>
                    <div className="hero-overlay bg-opacity-45"></div>
                    <div className="hero-content justify-center">
                        <div className='card rounded-md bg-base-300 p-4 md:py-7'>
                            <h1 className="text-secondary text-3xl text-center uppercase mb-4 font-bold">{utils.siteName}</h1>
                            <p className="text-center mb-5 capitalize">Construction Consultants Based in Nairobi, KENYA.</p>
                            <div className="flex justify-center">
                                <button onClick={() => openModal()} className='btn btn-secondary btn-md'>Get Quote</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div className='container mx-auto'>
                </div>
                    <h1 className="text-3xl text-center mb-7 mt-10">About Us</h1>
                    <div className='container mx-auto space-y-4'>
                        <p className='text-center'>
                            At Jahtech, we offer top-tier expertise in <strong>Construction Project Management</strong>, 
                            <strong>Architectural Plans and Designs</strong>, <strong>Building Cost Estimations</strong>, <strong>Bill of Quantities</strong>, and <strong>Work Program Scheduling</strong>. 
                            With our comprehensive suite of services, we ensure that every aspect of your construction project is meticulously planned and executed to meet the highest standards.
                        </p>
                        <p className='text-center'>
                            Our team of professionals brings years of experience and a deep understanding of the construction industry, 
                            making us the trusted choice for clients looking for reliable, efficient, and cost-effective solutions. 
                            Whether you're building a residential complex, commercial space, or infrastructure project, we provide tailored 
                            advice and hands-on management to deliver optimal results.
                        </p>
                        <p className='text-center'>
                            At Jahtech, we’re committed to bringing your vision to life with precision, quality, and professionalism. When it 
                            comes to construction consultancy, we are the partner you can count on for success. Let us turn your construction goals into reality.
                        </p>
                    </div>
                    <div className="mb-12 mt-20 container mx-auto">
                        <Contacts/>
                    </div>
            </div>
        </HelmetProvider>
    )
}

export default AboutPage